import React, { useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import ShowCard from "../ShowCard/ShowCard";
import { ReactComponent as Arrow } from "../../assets/Icons/filledArrow.svg";
import { useSelector } from "react-redux";
import { useWindowWidth } from "@react-hook/window-size";
import { rowItemCount } from "../../utils/utils";
const ShowsRow = ({thumbnailOrientation,season, data,type, title, metaData,watchVideoHandler,setVideoPlayer,similarVideos }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
    const isRTL = useSelector((state) => state?.rtl?.isRTL);
  
  const [seasonButton, setSeasonButton] = useState(false);
  const [details, setDetails] = useState();
  const [seasons, setSeasons] = useState([]);
  const [seasonName,setSeasonName] = useState("Season 1")
  const [rowItemsClass, setRowItemsClass] = useState("");
  const windowSize = useWindowWidth();

   const swiperRef = useRef(null);
  
    const [slidesPerView, setSlidesPerView] = useState(6);
    const [isLTR, setIsLTR] = useState(false);
  
    const handleSwiperInit = (swiperInstance) => {
      swiperRef.current = swiperInstance;
    };
  
    useEffect(() => {
      const updateSlidesPerView = () => {
        const width = window.innerWidth;
        if (width >= 1200) {
          setSlidesPerView(
            projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT
              ? projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT
              : thumbnailOrientation === "PORTRAIT"
              ? 6
              : 5
          );
        } else if (width >= 980) {
          setSlidesPerView(
            projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT
              ? projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT
              : 4
          );
        } else if (width >= 768) {
          setSlidesPerView(thumbnailOrientation === "PORTRAIT" ? 6 : 3);
        } else if (width >= 640) {
          setSlidesPerView(4);
        } else if (width >= 480) {
          setSlidesPerView(thumbnailOrientation === "PORTRAIT" ? 3 : 2);
        } else {
          setSlidesPerView(thumbnailOrientation === "PORTRAIT" ? 2 : 1);
        }
      };
  
      updateSlidesPerView();
      window.addEventListener("resize", updateSlidesPerView);
      return () => window.removeEventListener("resize", updateSlidesPerView);
    }, [thumbnailOrientation, projectInfo]);
  
    useEffect(() => {
      setIsLTR(details?.length > slidesPerView);
    }, [details?.length, slidesPerView]);
  
    const displayedShows = ( isRTL && isLTR ) ? [...details].reverse() : details;

  useEffect(() => {
    if (data && !season) {
      setDetails(data);
    } else {
        setDetails(data[0]?.videos)
      setSeasons(data);
    }
  }, [data]);
  useEffect(() => {
    if (windowSize > 980) {
      if (projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT) {
        setRowItemsClass(rowItemCount(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT));
      } else {
        setRowItemsClass("rowCount6");
      }
    } else {
      // setRowItemsClass("rowCount6");
      setRowItemsClass(rowItemCount(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT));
    }
  }, [projectInfo, windowSize]);
  return (
    <div className={`showsRow ${( isRTL && isLTR ) ? "ltr" : ""}`}>
      {title && <h1 className="heading">{title}</h1>}
      {season && (
        <div
          className={seasonButton?"seasonContainer zIndexController":"seasonContainer"}
          onClick={() => setSeasonButton(!seasonButton)}
        >
          <span className="selected">{seasonName}</span>
        <div className={season?.length>5?"listContainer scroll":"listContainer"}>

          <div className={seasonButton ? "list active" : "list"}>
            {seasons?.map((item, index) => (
              <div className="item" key={index} onClick={()=>{setDetails(item?.videos);setSeasonName(item?.season)}}>
                <span>{item?.season}</span>
              </div>
            ))}
           
            
          </div>
        </div>
          <Arrow className={seasonButton ? "active" : "no-active"} />
        </div>
      )}
      <Swiper
        key={`${isRTL}-${isLTR}`}
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={10}
        slidesPerView={slidesPerView}
        navigation={true}
        watchSlidesProgress
        loop={false}
        onSwiper={handleSwiperInit} 
        initialSlide={( isRTL && isLTR ) ? displayedShows.length - 1 : 0}
        className={ thumbnailOrientation === `PORTRAIT`? `portrait ${rowItemsClass}`: `landscape ${rowItemsClass}` }
        breakpoints={{
          320: {
            slidesPerView: thumbnailOrientation=="PORTRAIT"?2:1,
            spaceBetween: 2,
          },
          480:{
            slidesPerView: thumbnailOrientation=="PORTRAIT"?3:2,
            spaceBetween: 5,
          },
          640: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: thumbnailOrientation=="PORTRAIT"?6:3,
            spaceBetween: 1.5,
          },
          980: {
            slidesPerView:  projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT?projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT:4,
            spaceBetween: 10,   
          },
          1200: {
            slidesPerView:projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT ?(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT) :(thumbnailOrientation=="PORTRAIT"?6:5),
            spaceBetween: 1.5,
          },
        }}
      >
        {displayedShows?.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="show" onClick={()=>similarVideos?setVideoPlayer(null):watchVideoHandler(item)}>
              <ShowCard
                season={season ? true : false}
                data={item}
                imageUrl={true}
                metaData={metaData ? true : false}
                type={type}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ShowsRow;
