import React, { useEffect, useState } from "react";
import { ReactComponent as UserIcon } from "../../../assets/Icons/user_avatar.svg";
import { ReactComponent as FacebookIcon } from "../../../assets/Icons/facebook.svg";
import { ReactComponent as Arrow } from "../../../assets/Icons/filledArrow.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getUserDetails } from "../../../Redux/UserDetailsSlice/UserDetailsSlice";
import { ReactComponent as GoogleIcon } from "../../../assets/Icons/googleIcon.svg";

import * as service from "./service";
import { analytics2, authenticate, authenticate2 } from "../../../network/service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUser } from "../../../Redux/UserSlice/UserSlice";
import OtpModal from "../../../Components/Modals/OtpModal";
import { getAccessToken } from "../../../Redux/AToken/ATokenSlice";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { googleLogin, updateLoginWithoutCode } from "../Login/service";
import { getSessionId } from "../../../utils/utils";
import { getSessionIdValue } from "../../../Redux/SessionId/sessionIdSlice";
import SocialAccountLoginModal from "../../../Components/Modals/SocialAccountLoginModal";
import Translate from "../../../Components/Multilanguage/Translate";

const Register = () => {
  const accessToken = useSelector((state) => state.accessToken?.value);
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const user = useSelector((state) => state?.user?.value);
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [otpModal, setOtpModal] = useState(false);
  const [userRegisterId, setUserRegisterId] = useState();
  const [redirectUrl, setRedirectUrl] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [googleUser, setGoogleUser] = useState();
  const [socialLoginModalType, setSocialLoginModalType] = useState({});
  const [showSocialLoginModal, setShowSocialLoginModal] = useState(false);

  const appInfo = {
    accessToken: accessToken,
    projectDetails: projectInfo,
  };
  useEffect(() => {
    window.scroll(0, 0);
    if (location?.state?.path) {
      setRedirectUrl(location?.state?.path);
    } else {
      setRedirectUrl("/home");
    }
  }, [location?.state]);

  useEffect(() => {
    if (googleUser) {
      getgoogleUserData()
    }
  }, [googleUser]);

  const getgoogleUserData = async () => {
    const response = await googleLogin(googleUser?.access_token)
    if (response?.status === 200) {
      const values = {
        googleId: response?.data?.id,
        loginType: "google",
        firstName: response?.data?.name,
        email: response?.data?.email
      }
      loginWithoutCode(values)
    }
  }

  const loginWithoutCode = async (values) => {
    const response = await updateLoginWithoutCode(appInfo, values)
    if (response?.status === 200) {
      localStorage.setItem("userId", response?.data?.data[0]?.user_id);
      tokenAuthenticate();
      updateDeviceAnalytics(
        response?.data?.data[0]?.user_id,
        appInfo,
        response?.data?.data[0]?.user_email
      );
      dispatch(
        getUser({
          user: response?.data?.data[0]?.user_id,
        })
      );
      navigate(redirectUrl, { state: location?.state });
    }
    else if(response?.status === 204)
      {
          setSocialLoginModalType({
            heading:`Already registered with the same email. Do you want to link your ${values?.loginType} account?`,
            values:values
          });
          setShowSocialLoginModal(true);
      }
  }


  const googleLoginHandler = useGoogleLogin({
    onSuccess: (codeResponse) => setGoogleUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });


  const handleUpdate = (item) => {
    const { name, value } = item?.target;
    handleChange(name, value);
  };
  const handleChange = (name, value) => {
    setValues({
      ...values,
      [name]: value,
    });
  };
  const validateRegister = () => {
    let error = {};
    let validateStatus = true;
    const {
      email,
      password,
      confirmPassword,
      firstName,
      lastName,
      gender,
      dob,
    } = values;
    let nameRegex = /([A-z])+(.?[a-zA-Z])*('?[a-zA-Z])*/;
    let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let passwordRegex =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
    if (!email) {
      error.email = <Translate textKey={'email_required_message'}/>;
      validateStatus = false;
    } else if (!email?.match(emailRegex)) {
      error.email = <Translate textKey={'valid_email_message'}/>;
      validateStatus = false;
    }
    if (!password) {
      error.password = <Translate textKey={'password_required'}/>;
      validateStatus = false;
    }
    // else if (!password?.match(passwordRegex)) {
    //   error.password =
    //     "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special characters!";
    //   validateStatus = false;
    // }
    if (password !== confirmPassword) {
      error.confirmPassword = <Translate textKey={'password_not_matching_message'}/>;
      validateStatus = false;
    }
    if (!firstName) {
      error.firstName = <Translate textKey={'first_name_required'}/>;
      validateStatus = false;
    } else if (!firstName.match(nameRegex)) {
      error.firstName = <Translate textKey={'name_alphabet_message'}/>;
      validateStatus = false;
    }
    if (!lastName) {
      error.lastName = <Translate textKey={'last_name_required'}/>;
      validateStatus = false;
    } else if (!lastName.match(nameRegex)) {
      error.lastName = <Translate textKey={'name_alphabet_message'} />;
      validateStatus = false;
    }
    // if (!dob) {
    //   error.dob = "Please enter date of birth";
    //   validateStatus = false;
    // }
    // if (!gender) {
    //   error.gender = "Select a gender!";
    //   validateStatus = false;
    // }
    setErrors(error);
    return validateStatus;
  };
  const updateDeviceAnalytics = async (userId, appInfo, userEmail) => {
    await analytics2(userId, appInfo, userEmail);
  };
  const tokenAuthenticate = async () => {
    const response = await authenticate(appInfo);
    if (response?.status === 200) {
      dispatch(
        getAccessToken({
          accessToken: response?.data?.token,
        })
      );
      dispatch(
        getUserDetails({
          userDetails: response?.data,
        })
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const validateStatus = validateRegister();
      if (validateStatus) {
        const response = await service.registerUser(appInfo, values);
        if (response?.status === 200) {
          localStorage.setItem("userId", response?.data?.data[0]?.user_id)
          if (values?.referralCode) {
            localStorage.setItem("referralCode", values?.referralCode)
          }
          toast.success(response?.data?.message, {
            position: "top-center",
          });
          setUserRegisterId(response?.data?.data[0]?.user_id);
          tokenAuthenticate();
          updateDeviceAnalytics(
            response?.data?.data[0]?.user_id,
            appInfo,
            values?.email
          );
          dispatch(
            getUser({
              user: response?.data?.data[0]?.user_id,
            })
          );
          const sessionId = await getSessionId(appInfo);
          dispatch(
            getSessionIdValue({
              sessionId: sessionId,
            })
          );
          navigate(redirectUrl, { replace: true ,state:{...location.state}});
        } else if (
          response?.status === 201 &&
          appInfo?.projectDetails?.projectConfig?.config
            ?.REGISTRATION_OTP_REQUIRED === "true"
        ) {
          toast.success(response?.data?.message, {
            position: "top-center",
          });
          setOtpModal(true);
          setUserRegisterId(response?.data?.user_id);
        }
        else {
          toast.error(response?.data?.message, {
            position: "top-center",
          });
        }
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "top-center",
      });
    }
  };

  return (
    <div className="registerPage">
      {otpModal && (
        <OtpModal
          setOtpModal={setOtpModal}
          appInfo={appInfo}
          userRegisterId={userRegisterId}
          updateDeviceAnalytics={updateDeviceAnalytics}
          tokenAuthenticate={tokenAuthenticate}
          values={values}
          urlToRedirect={redirectUrl}
        />
      )}
      {showSocialLoginModal && (
        <SocialAccountLoginModal
          modalType={socialLoginModalType}
          setShowSocialLoginModal={setShowSocialLoginModal}
        />
      )}
      <ToastContainer />
      <div className="registerContainer">
        <div className="top">
          <div className="userIconContainer">
            <UserIcon />
          </div>
          <h1><Translate textKey={'register_title'}/></h1>
          <h6><Translate textKey={'register_sub_title'}/></h6>
          {
            projectInfo?.projectConfig?.config?.SOCIAL_LOGIN_REQUIRED === "true" &&
            <>
              {
                projectInfo?.projectConfig?.config?.FACEBOOK_LOGIN_REQUIRED === "true" &&
                <div className="facebookContainer">
                  <div className="background"></div>
                  <div className="contents">
                    <div className="left">
                      <FacebookIcon />
                    </div>
                    <div className="right">
                      <span><Translate textKey={'sign_in_via_facebook'} /></span>
                    </div>
                  </div>
                </div>
              }

              {
                projectInfo?.projectConfig?.config?.GOOGLE_LOGIN_REQUIRED === "true" &&
                <div
                  className="googleContainer"
                  onClick={() => {
                    googleLoginHandler();
                  }}
                >
                  <div className="background"></div>
                  <div className="contents">
                    <div className="left">
                      <GoogleIcon />
                    </div>
                    <div className="right">
                      <span><Translate textKey={'google_sign_in'} /></span>
                    </div>
                  </div>
                </div>

              }


              <div className="seperate">
                <span className="line"></span>
                <span><Translate textKey={'or'}/></span>
                <span className="line"></span>
              </div>
            </>
          }
        </div>
        <div className="bottom">
          <h3><Translate textKey={'register_via_email'} /></h3>
          <form action="post">
            <div className="oneByOne">
              <input
                type="text"
                placeholder="First Name"
                name="firstName"
                onChange={(item) => {
                  handleUpdate(item);
                }}
              />
            </div>{" "}
            {errors.firstName && (
              <span className="error">{errors.firstName}</span>
            )}
            <div className="oneByOne">
              <input
                type="text"
                placeholder="Last Name"
                name="lastName"
                onChange={(item) => {
                  handleUpdate(item);
                }}
              />
            </div>
            {errors.lastName && (
              <span className="error">{errors.lastName}</span>
            )}
            <div className="oneByOne">
              <input
                type="email"
                placeholder="Email"
                name="email"
                onChange={(item) => {
                  handleUpdate(item);
                }}
              />
            </div>{" "}
            {errors.email && <span className="error">{errors.email}</span>}
            <div className="oneByOne">
              <input
                type="password"
                placeholder="Password"
                name="password"
                onChange={(item) => {
                  handleUpdate(item);
                }}
              />
            </div>{" "}
            {errors.password && (
              <span className="error">{errors.password}</span>
            )}
            <div className="oneByOne">
              <input
                type="password"
                placeholder="Confirm Password"
                name="confirmPassword"
                onChange={(item) => {
                  handleUpdate(item);
                }}
              />
            </div>
            {errors.confirmPassword && (
              <span className="error">{errors.confirmPassword}</span>
            )}
            <div className="oneByOne">
              <input
                type="text"
                placeholder="Referral Code (Optional)"
                name="referralCode"
                onChange={(item) => {
                  handleUpdate(item);
                }}
              />
            </div>
            {/* <div className="oneByTwo">
              <div className="containers">
                <div className="left">
                  <label htmlFor="dob">Birthday</label>

                  <input
                    type="date"
                    name="dob"
                    onChange={(item) => {
                      handleUpdate(item);
                    }}
                  />
                  {errors.dob && <span className="error">{errors.dob}</span>}
                </div>
                <div className="right">
                  <label htmlFor="gender">Gender</label>
                  <div className="genderContainer">
                    <Arrow />
                    <select
                      name="gender"
                      id="gender"
                      onChange={(item) => {
                        handleUpdate(item);
                      }}
                    >
                      <option value="Select">Select</option>
                      <option value="male">Male</option>
                      <option value="Female">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </div>{" "}
                  {errors.gender && (
                    <span className="error">{errors.gender}</span>
                  )}
                </div>
              </div>
            </div> */}
            {/* {errors.message && (
                    <span className="error">{errors.message}</span>
                  )} */}
            <div
              className="buttonContainer"
              onClick={(event) => {
                handleSubmit(event);
              }}
            >
              <div className="background"></div>
              <button><Translate textKey={'register'} /></button>
            </div>
          </form>
          <p>
          <Translate textKey={'register_agree_text'} /> <br /> <span onClick={() => navigate("/terms-and-conditions")}><Translate textKey={'terms_of_use'}/></span>{" "}
          <Translate textKey={'and'} /> <span onClick={() => navigate("/privacy-policy")}><Translate textKey={'privacy_policy'} /></span>
          </p>
          <h4 className="signIn">
           <Translate textKey={'register_sign_in_text'} />{" "}
            <span onClick={() => navigate("/login")}><Translate textKey={'sign_in'}/></span>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Register;
