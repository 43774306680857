
import React from "react";
import { convertAdUrl } from "../../utils/utils";
import Translate from "../Multilanguage/Translate";

const ContinueWatchingModel = (props) => {

  const ContinueWatchingHandler = async (value) => {
    if (value === "yes") {
      if(props.subscribedUser === true)
      {
        props?.setIsContinueWatchingModal(false);
        props?.playVideo( props?.videoDetails?.watched_duration);
      }
      else{  
        props?.setAdUrl(convertAdUrl(props?.videoDetails,props?.showDetails,props?.appInfo))
        props?.setIsContinueWatchingModal(false);
        props?.playVideo( props?.videoDetails?.watched_duration );
      }
    } else if (value === "no") {
      if(props.subscribedUser=== true)
      {
        props?.playVideo();
        props?.setIsContinueWatchingModal(false);
      }
      else{
        props?.setAdUrl(convertAdUrl(props?.videoDetails,props?.showDetails,props?.appInfo))
        props?.playVideo();
        props?.setIsContinueWatchingModal(false);
      }
      

    }
  };
  return (
    <div className="continueWatching">
      <div className="overlayModal"></div>
      <div className="contents">
        <h1><Translate textKey={'continue_from_stopped'} /></h1>
        <div className="buttons">
          <div
            className="buttonContainer"
            onClick={() => ContinueWatchingHandler("yes")}
          >
            <div className="background"></div>
            <button><Translate textKey={'yes'} /></button>
          </div>

          <div
            className="buttonContainer"
            onClick={() => ContinueWatchingHandler("no")}
          >
            <div className="background"></div>
            <button><Translate textKey={'no'} /></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContinueWatchingModel;
