import React, { useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import ShowCard from "../ShowCard/ShowCard";
import Categories from "../../Screens/homepage/Components/Categories";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Translate from "../Multilanguage/Translate";

const CategoriesShowCard = ({ data,thumbnailOrientation }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const isRTL = useSelector((state) => state?.rtl?.isRTL);
  const navigate = useNavigate();
    const swiperRef = useRef(null);
  
    const [slidesPerView, setSlidesPerView] = useState(6);
    const [isLTR, setIsLTR] = useState(false);
  
    const handleSwiperInit = (swiperInstance) => {
      swiperRef.current = swiperInstance;
    };
  
    useEffect(() => {
      const updateSlidesPerView = () => {
        const width = window.innerWidth;
        if (width >= 980) {
          setSlidesPerView(
            projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT
              ? projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT
              : 4
          );
        } else if (width >= 768) {
          setSlidesPerView(thumbnailOrientation === "PORTRAIT" ? 6 : 4);
        } else if (width >= 640) {
          setSlidesPerView(4);
        } else if (width >= 480) {
          setSlidesPerView(thumbnailOrientation === "PORTRAIT" ? 3 : 2);
        } else {
          setSlidesPerView(thumbnailOrientation === "PORTRAIT" ? 2 : 1);
        }
      };
  
      updateSlidesPerView();
      window.addEventListener("resize", updateSlidesPerView);
      return () => window.removeEventListener("resize", updateSlidesPerView);
    }, [thumbnailOrientation, projectInfo]);
  
    useEffect(() => {
      setIsLTR(data?.shows?.length > slidesPerView);
    }, [data?.shows?.length, slidesPerView]);
  
    const displayedShows = ( isRTL && isLTR ) ? [...data?.shows].reverse() : data?.shows;
  

  return (
    <div className={`categoriesShowCard ${( isRTL && isLTR ) ? "ltr" : ""}`}>
      <div className="backgroundImageContainer">
      
        <img className="bgImage" src={data?.banner} alt="Banner" />
        <div className="metaInfo">

        <h1 className="categoryName" onClick={() =>
            navigate(`/category/${data?.key}`, {
              state: {careers:data?.key,type:data?.type,banner:data?.banner},
            })
          }>{data?.category_name}</h1>
        <p className="description">{data?.synopsis}</p>
        </div>

        <div className="AllShows">
          <div
            className="categoryNameContainer"
            onClick={() =>
              navigate(`/category/${data?.key}`, {
                state: {careers:data?.key,type:data?.type},
              })
            }
          >
            <h1 className="seeAll"><Translate textKey={'see_all'}/> ({data?.shows_count ? data?.shows_count : data?.shows?.length})</h1>
          </div>
          <Swiper
            key={`${isRTL}-${isLTR}`}
            modules={[Navigation]}
            spaceBetween={5}
            slidesPerView={slidesPerView}
            navigation={true}
            watchSlidesProgress
            loop={false}
            onSwiper={handleSwiperInit} 
            initialSlide={( isRTL && isLTR ) ? displayedShows.length - 1 : 0}
            breakpoints={{
              320: {
                slidesPerView: thumbnailOrientation=="PORTRAIT"?2:1,
                spaceBetween: 2,
              },
              480:{
                slidesPerView: thumbnailOrientation=="PORTRAIT"?3:2,
                spaceBetween: 5,
              },
              640: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: thumbnailOrientation=="PORTRAIT"?6:4,
                spaceBetween: 1.5,
              },
              980:{
                slidesPerView: projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT?projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT:4,
                spaceBetween: 1.5,
              },
              // 1200:{
              //   slidesPerView: thumbnailOrientation=="PORTRAIT"?6:4,
              //   spaceBetween: 1.5,
              // },
              // 1700:{
              //   slidesPerView: thumbnailOrientation=="PORTRAIT"?7:4,
              //   spaceBetween: 1.5,
              // }
            }}
          >
            {displayedShows?.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="shows">
                  <ShowCard
                    data={item}
                    season={false}
                    type={data?.type}
                    metaData={true}
                  />
                </div>
              </SwiperSlide>
            ))}  
          </Swiper>

          
          
        </div>
      </div>
      <div className="bottomGradient"></div>
    </div>
  );
};

export default CategoriesShowCard;
