import React, { useEffect, useState } from 'react'
import { fetchAppInfo } from '../../network/service';
import parse from "html-react-parser";
import Loading from '../../Components/Loading/Loading';
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import bgImage from "../../assets/Images/about-banner.jpg";
import { ReactComponent as AppStore } from "../../assets/Icons/appstore.svg";
import { ReactComponent as PlayStore } from "../../assets/Icons/playstore.svg";
import RokuStore from "../../assets/Images/roku.jpg";
import FireStore from "../../assets/Images/firetv.jpg";
import Translate from '../../Components/Multilanguage/Translate';

const AboutUs = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);
  const logo = projectInfo?.projectConfig?.config?.FAVICON || "";

  useEffect(() => {
    window.scroll(0,0)
    fetchAboutUs();
  }, []);

  const fetchAboutUs = async () => {
    setLoading(true)
    try{
      const params = {
        type: "ABOUT_US",
      };
      const response = await fetchAppInfo(params);
     
      if (response?.status === 200) {
        setData(response?.data?.data?.value);
        setLoading(false);

      }
    }catch(err){
      setLoading(false);
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
    
  };

  if(loading){
    return(
      <Loading/>
    )
  }

  return (
    <div
      className="jwmAboutUs"
      style={{
        backgroundImage: `url("${bgImage}")`,
        height: "100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <ToastContainer />
      <div className="wrapper">
        <div className="content-container">
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>
          <div className="about-content">
            <a className="link" href="https://justwatchme.tv/">{projectInfo?.projectConfig?.config?.TAB_TITLE}</a>
            {data ? parse(data) : <h1 className="noData"><Translate textKey={'no_data'} /></h1>}
            <div className="bottomContainer">
                <div className="stores">
                {projectInfo?.projectConfig?.config?.FIRE_TV_APPSTORE_URL && (
                    <img src={FireStore}
                    onClick={() =>
                        window.open(
                        projectInfo?.projectConfig?.config?.FIRE_TV_APPSTORE_URL,
                        "blank"
                        )
                    }
                    />
                )}
                {projectInfo?.projectConfig?.config?.ROKU_CHANNEL_STORE_URL && (
                    <img src={RokuStore}
                    onClick={() =>
                        window.open(
                        projectInfo?.projectConfig?.config?.ROKU_CHANNEL_STORE_URL,
                        "blank"
                        )
                    }
                    />
                )}
                {projectInfo?.projectConfig?.config?.IOS_APP_STORE_URL && (
                    <AppStore
                    onClick={() =>
                        window.open(
                        projectInfo?.projectConfig?.config?.IOS_APP_STORE_URL,
                        "blank"
                        )
                    }
                    />
                )}
                {projectInfo?.projectConfig?.config?.ANDROID_PLAY_STORE_URL && (
                    <PlayStore
                    onClick={() =>
                        window.open(
                        projectInfo?.projectConfig?.config?.ANDROID_PLAY_STORE_URL,
                        "blank"
                        )
                    }
                    />
                )}
                </div>
                </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
