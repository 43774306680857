import React from 'react'
import { ReactComponent as CloseIcon } from "../../assets/Icons/cross.svg";
import { useDispatch } from 'react-redux';
import { getHowItWorksModal } from '../../Redux/HowItWorksModal/howItWorksSlice';

const HowItWorks = () => {
    const dispatch = useDispatch()
    const closeHandler = () => {
        dispatch(
            getHowItWorksModal({
              howItWorksModal: {isOpen:false},
            })
          );
    }
  return (
    <div className='howItWorks'>
      <div className="overlayModal"></div>
        <div className="container">
        <div className="closeIconContainer" onClick={()=>closeHandler()}>
          <CloseIcon />
        </div>
            <video autoPlay src="https://gizmeon.mdc.akamaized.net/PUB-50030/tutorial.mp4" controls></video>
        </div>
    </div>
  )
}

export default HowItWorks
