import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { useSelector } from "react-redux";
import Translate from "../../Multilanguage/Translate";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const ApplePrivateLoginModal = ({ isEmailForm, setApplePrivateLogin ,setApplePrivateEmail}) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const [values, setValues] = useState()
  const [open, setOpen] = React.useState(isEmailForm);

  useEffect(() => {
    setOpen(isEmailForm);
  }, [isEmailForm]);

  const handleCloseEmailForm = (event,reason) => {
    if (reason && reason == "backdropClick") 
    return;
    setOpen(false);
    setTimeout(() => {
      setApplePrivateLogin(false);
    }, 500);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const emailRegex = /^\w+([\.\+\-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const {email} = values;

    if (!email) {
      toast.error("Field cannot be empty!", {
        position: "bottom-center",
      });
    } else {
      if (!email?.match(emailRegex)) {
        toast.error("Enter a valid email!", {
          position: "bottom-center",
        });
      } else {
        setApplePrivateEmail(email)
      }
    }
  };

  const handleChange = (target)=> {

    const {name, value} = target;
    setValues({
      ...values,
      [name]: value
    })
  }

  return (
    <div className="loginModal">
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleCloseEmailForm}
        aria-describedby="alert-dialog-slide-description"
        transitionDuration={500}
        className="emailForm"
        maxWidth="xl"
        id="loginEmailDialog"
      >
        <h1>
          <img src={projectInfo?.projectConfig?.config?.LOGO} alt="" />
        </h1>
        <DialogContent>
          <form>
                      
            <label htmlFor="email"><Translate textKey={'email_address'} /></label>
            <input
              type="email"
              name="email"
              value={values?.email}
              onChange={(e) => handleChange(e.target)}
              id="email"
            />
            
            <div className="buttonContainer" onClick={(e) => submitHandler(e)}>
              <div className="background"></div>
              <button type="submit"><Translate textKey={'next'} /></button>
            </div>
        <label><Translate textKey={'apple_privatelogin_message'} /></label>

          </form>
        </DialogContent>
      </Dialog>
        
    </div>
  );
};

export default ApplePrivateLoginModal;
