import { fetchApiData, updateApiData } from "../../utils/utils";

export const getSubscriptionList = async (appInfo) => {
  const api = `subscription/list`;
  return await fetchApiData(appInfo, api);
};

export const checkIfEmailExists = async (appInfo, params) => {
  const api = `account/user/exists`;
  return await fetchApiData(appInfo, api, params);
};

export const getUpgradeSubscriptionList = async (appInfo, subId) => {
  const api = "subscription/premium";
  const params = {
    pubid: appInfo?.projectDetails?.projectConfig?.pubid,
    subscription_id: subId,
  };
  return await fetchApiData(appInfo, api, params);
};

export const applyCouponCode = async (appInfo, coupon, subId) => {
  const api = `coupon/check?code=${coupon}&sub_id=${subId}`;
  return await fetchApiData(appInfo, api);
};

export const stripeSession = async (appInfo, values) => {
  const pubId = appInfo?.projectDetails?.projectConfig?.pubid;
  const countryCode = appInfo?.projectDetails?.geoInfo?.countryCode;
  const params = {
    pubid: pubId,
    sub_id: values?.subId,
    country_code: countryCode,
    coupon_id: values?.couponId,
  };
  const api = "subscription/stripe/session";
  return await fetchApiData(appInfo, api, params);
};

export const getAndroidSubscriptionDetails = async (appInfo, androidtoken) => {
  const pubId = appInfo?.projectDetails?.projectConfig?.pubid;
  const countryCode = appInfo?.projectDetails?.geoInfo?.countryCode;
  const params = {
    pubid: pubId,
    key: androidtoken,
    country_code: countryCode,
  };
  const api = "subscription/details";
  return await fetchApiData(appInfo, api, params);
};
export const getVideoSubscription = async (appInfo, id, type, list = null) => {
  const api = `subscription/active`;
  const referralCode = localStorage.getItem("referralCode");
  let params = {
    type: 'list',
    video_id: type === "video" ? id : null,
    show_id: type === "show" ? id : null,
    event_id: type === "event" ? id : null,
    channel_id: type === "channel" ? id : null,
    coupon_code: referralCode,
  };
  if (list) {
    params.type = list;
  }

  return await fetchApiData(appInfo, api, params);
};
export const getIdeaBizToken = async (appInfo) => {
  const api = "subscription/ideabiz/token"
  return await fetchApiData(appInfo, api)
}

export const getUpcomingInvoice = async (data, appInfo) => {
  let params = {
    subscription_id: data?.subscription_id
  }
const api = "subscription/stripe/invoice";
return await fetchApiData(appInfo, api, params);
}

export const subscriptionUpdate = async (data, appInfo, authenticated) => {
  let requestBody = {
    values: {
      subscription_id: data?.subscription_id,
      amount: data?.price,
      mode_of_payment: 'stripe',
      status: "success",
      transaction_type: "1",
      authenticated: authenticated ? true : false
    }
  }
  const api = "subscription/change";
  return await updateApiData(appInfo, api, requestBody);
}

