import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as Closeicon } from "../../assets/Icons/cross.svg";
import { getPlayerToken } from "../../network/service";
import { fetchApiDataV2 } from "../../utils/utils";
import VideoJS from "../VideoJsPlayer/VideoJS";
import { closeTrailerModal } from "../../Redux/TrailerModal/trailerModalSlice";
import Translate from "../Multilanguage/Translate";

const TrailerModal = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const trailerModal = useSelector((state) => state.trailerModal.value);
  const { teaser, teaserVanityUrl, appInfo, showDetails } = trailerModal;
  const refOne = useRef(null);
  const dispatch = useDispatch();

  const [trailerUrl, setTrailerUrl] = useState("");
  const [videoPlayer, setVideoPlayer] = useState(null);
  const [title, setTitle] = useState("");
  const [director, setDirector] = useState();
  const [year, setYear] = useState();


  useEffect(() => {
    setVideoPlayer(null);
    if (teaser) {
      fetchPlayerToken();
    } else if (teaserVanityUrl) {
      fetchTrailer(teaserVanityUrl);
    }
  }, [teaser, teaserVanityUrl]);

  useEffect(() => {
    if (trailerUrl) {
      const videoJsOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        controlBar: {
          pictureInPictureToggle: false,
        },
        sources: [
          {
            src: `${trailerUrl}`,
            type: "application/x-mpegURL",
          },
        ],
      };

      setVideoPlayer(<VideoJS options={videoJsOptions} />);
    }
  }, [trailerUrl]);

  const getTrailer = async (appInfo, vanityUrl) => {
    const api = `trailer/${vanityUrl}`;
    return await fetchApiDataV2(appInfo, api);
  };

  const fetchTrailer = async (vanityUrl) => {
    try {
      const response = await getTrailer(appInfo, vanityUrl);
      const data = response?.data?.data;
      if (data) {
        setTitle(data?.title);
        setDirector(data?.director);
        setYear(data?.year);
  
        if (data.teaser) {
          let arr = data.teaser.split("/").reverse();
          if (arr.length >= 2) {
            const playerTokenResponse = await getPlayerToken(appInfo, arr[1]);
            if (playerTokenResponse?.status === 200) {
              setTrailerUrl(
                `https://api.gizmott.com/api/v1/playlistV2/playlist.m3u8?id=${arr[1]}&token=${playerTokenResponse?.data?.data}&type=trailer&pubid=${projectInfo?.projectConfig?.pubid}`
              );
            }
          } else {
            setTrailerUrl(data.teaser);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching trailer:", error);
    }
  };
  

  const fetchPlayerToken = async () => {
    try {
      let arr = teaser?.split("/").reverse();
      if (arr?.length >= 2) {
        const playerTokenResponse = await getPlayerToken(appInfo, arr[1]);
        if (playerTokenResponse?.status === 200) {
          setTrailerUrl(
            `https://api.gizmott.com/api/v1/playlistV2/playlist.m3u8?id=${arr[1]}&token=${playerTokenResponse?.data?.data}&type=trailer&pubid=${projectInfo?.projectConfig?.pubid}`
          );
        }
      }
    } catch (error) {
      console.error("Error fetching player token:", error);
    }
  };

  const handleClickOutSide = (e)=> {
    if (refOne?.current && !refOne?.current?.contains(e.target)) {
      dispatch(closeTrailerModal());
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutSide);
    return () => {
      document.removeEventListener("mousedown", handleClickOutSide);
    };
  }, []);

  return (
    <div className="trailerModal">
      <div className="overlayModal" onClick={handleClickOutSide}></div>
      <div className="head">
        <div className="left">
            <h4 className="heading"><Translate textKey={'trailer'} /></h4>
          <h1 className="title">{title || showDetails?.show_name}</h1>
          <span className="director">{director || showDetails?.director}</span>
          <span className="year">{year || showDetails?.year}</span>
        </div>
        <div className="right">
          <div className="closeIcon" onClick={() => dispatch(closeTrailerModal())}>
            <Closeicon />
          </div>
        </div>
      </div>
      <div className="trailerPlayer">{videoPlayer}</div>
    </div>
  );
};

export default TrailerModal;
