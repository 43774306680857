import React from "react";
import { ReactComponent as FacebookIcon } from "../../assets/Icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "../../assets/Icons/instagram.svg";
import { ReactComponent as TwitterIcon } from "../../assets/Icons/twitter.svg";
import { ReactComponent as LinkedInIcon } from "../../assets/Icons/linkedIn.svg";
import { ReactComponent as AppStore } from "../../assets/Icons/appstore.svg";
import { ReactComponent as PlayStore } from "../../assets/Icons/playstore.svg";
import partnerLogo from "../../assets/Images/partnerlogo.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Translate from "../Multilanguage/Translate";

const Footer = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const navigate = useNavigate();
  const linkHandler = (data) => {
    if (data?.seo_url) {
      navigate(data?.seo_url);
    } else if (data?.link) {
      window.open(data?.link);
    }
  };

  return (
    <div className="footerContainer">
      <div className="wrapper">
        <div className="topContainer">
          <div className="left">
            <div className="logoContainer">
              {projectInfo?.projectConfig?.pubid === 50030 ? (
                <img src={projectInfo?.projectConfig?.config?.FAVICON} alt="Logo" className="jwm" />
              ) : (
              <img src={projectInfo?.projectConfig?.config?.LOGO} alt="Logo" />
              )}
            </div>
          </div>
          <div className={`${projectInfo?.projectConfig?.pubid === 50030 ? "right jwm" : "right"}`}> 
          {projectInfo?.projectConfig?.pubid === 50030 && <img src={partnerLogo} />}
            <ul className="social">
            {projectInfo?.projectConfig?.pubid === 50030 && <li><img src={partnerLogo} /></li>}
              {projectInfo?.projectConfig?.config?.FACEBOOK && (
                <li
                  onClick={() =>
                    window.open(
                      projectInfo?.projectConfig?.config?.FACEBOOK,
                      "blank"
                    )
                  }
                >
                  <FacebookIcon />
                </li>
              )}

              {projectInfo?.projectConfig?.config?.INSTAGRAM && (
                <li
                  onClick={() =>
                    window.open(
                      projectInfo?.projectConfig?.config?.INSTAGRAM,
                      "blank"
                    )
                  }
                >
                  <InstagramIcon />
                </li>
              )}
              {projectInfo?.projectConfig?.config?.TWITTER && (
                <li
                  onClick={() =>
                    window.open(
                      projectInfo?.projectConfig?.config?.TWITTER,
                      "blank"
                    )
                  }
                >
                  <TwitterIcon />
                </li>
              )}
              {projectInfo?.projectConfig?.config?.LINKEDIN && (
                <li
                  onClick={() =>
                    window.open(
                      projectInfo?.projectConfig?.config?.LINKEDIN,
                      "blank"
                    )
                  }
                >
                  <LinkedInIcon />
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="centerContainer">
          <div className="informationContainer">
            {projectInfo?.projectConfig?.config?.WEBSITE_FOOTERS?.map((data, index) => (
              <div className="items">
                <h6 className="heading"><Translate textKey={data?.title} /></h6>
                <ul>
                  {data?.items?.map((item, index) => (
                    <li>
                      <span onClick={() => linkHandler(item)}><Translate textKey={item?.title} /></span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="bottomContainer">
        <div className="stores">
          {projectInfo?.projectConfig?.config?.IOS_APP_STORE_URL && (
            <AppStore
              onClick={() =>
                window.open(
                  projectInfo?.projectConfig?.config?.IOS_APP_STORE_URL,
                  "blank"
                )
              }
            />
          )}
          {projectInfo?.projectConfig?.config?.ANDROID_PLAY_STORE_URL && (
            <PlayStore
              onClick={() =>
                window.open(
                  projectInfo?.projectConfig?.config?.ANDROID_PLAY_STORE_URL,
                  "blank"
                )
              }
            />
          )}
        </div>
        <p>{projectInfo?.projectConfig?.config?.COPYRIGHT_TEXT}</p>
        <p><Translate textKey={'all_rights_reserved'} /></p>
        {
          projectInfo?.projectConfig?.config?.FOOTER_PAYMENTS_REQUIRED === "true" &&
          <div className="typeOfPayments">
            <i className="fa fa-2x fa-cc-discover"></i>
            <i class="fa-brands fa-2x fa-cc-mastercard"></i>
            <i className="fa fa-2x fa-cc-paypal"></i>
            <i className="fa fa-2x fa-cc-visa"></i>
          </div>
        }
      </div>
    </div>
  );
};

export default Footer;
