import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { loginWithTVCode } from "./service";
import { useSelector,useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getSessionId } from "../../utils/utils";
import { getSessionIdValue } from "../../Redux/SessionId/sessionIdSlice";
import Translate from "../../Components/Multilanguage/Translate";

const TvActivationReverse = () => {
    const projectInfo = useSelector((state) => state?.projectInfo?.value);
    const accessToken = useSelector((state) => state?.accessToken?.value);
    const user = useSelector((state) => state?.user?.value);
    const [inputValue, setInputValue] = useState("");
    const location = useLocation();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const params = new URLSearchParams(location?.search);
    let code = params?.get("code");

    const appInfo = {
        projectDetails: projectInfo,
        accessToken: accessToken,
    };
    useEffect(() => {
        if (code) {
            setInputValue(code);
        }
    }, [code]);

    const registerHandler = (e) => {
        e.preventDefault()
        if (inputValue) {
            updateTVActivationCode()
        } else {
            toast.error(<Translate textKey={'fill_field_message'}/>, {
                position: "bottom-center",
            });
        }
    };

    const updateTVActivationCode = async () => {
        try {
            const response = await loginWithTVCode(appInfo, inputValue);
            if (response?.status === 200) {
                toast.success(response?.data?.message, {
                    position: "bottom-center",
                });
                setInputValue("")
                setTimeout(() => {
                    navigate("/home")
                }, 1000);
                const sessionId = await getSessionId(appInfo);
                dispatch(
                getSessionIdValue({
                    sessionId: sessionId,
                })
                );
            }
        } catch (err) { }
    };
    return (
        <div className="tvActivationReverse">
            <div className="wrapper">
                <div className="topHeader"  style={{
                       borderBottom:projectInfo?.projectConfig?.config?.BACKGROUND_COLOR || "var(--border-color)" 
                      }}>
                    <h1><Translate textKey={'register_device'}/></h1>
                </div>
                <div className="bottomContainer">
                    <h2><Translate textKey={'register_tv_device'}/></h2>
                    <p><Translate textKey={'enter_code_to_register'} /> {projectInfo?.projectConfig?.config?.TAB_TITLE} <Translate textKey={'account_small'}/></p>

                    <div className="inputContainer">
                        <form>
                            <label htmlFor="registrationCode"><Translate textKey={'registration_code'} /></label>
                            <input
                                type="text"
                                id="registrationCode"
                                value={inputValue || ""}
                                placeholder="e.g. HQ2W4Z"
                                onChange={(e) => setInputValue(e.target.value)}
                            />
                            <button type="submit" onClick={(e) => registerHandler(e)} style={{
                                backgroundColor: projectInfo?.projectConfig?.config?.BACKGROUND_COLOR ? "var(--grey-color)" : "var(--default-bg-color)" }}><Translate textKey={'register_device_button'}/></button>
                        </form>
                        <p className="terms">
                        <Translate textKey={'continue_agree_message'} /><span><Link to="/terms-and-conditions">{projectInfo?.projectConfig?.config?.TAB_TITLE}<Translate textKey={'terms_and_conditions'}/></Link></span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TvActivationReverse;
