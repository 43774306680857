import React, { useEffect, useState } from "react";
import ReplyField from "./ReplyField";
import { ReactComponent as LikeIcon } from "../../../assets/Icons/like.svg";
import { ReactComponent as ReplyIcon } from "../../../assets/Icons/reply.svg";
import { ReactComponent as FlagIcon } from "../../../assets/Icons/flag.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/Icons/delete.svg";
import CommentReplies from "./CommentReplies";
import initializeFirebase from '../../../firebaseConfig';
import Translate from "../../../Components/Multilanguage/Translate";

import { child, get, increment, onValue, push, ref, set, update } from "firebase/database";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getCurrentUTCTimestamp, timeDifferenceWithCurrenttime } from "../../../utils/utils";


const CommentItem = ({ data,projectInfo }) => {
  
  const firebaseDB = initializeFirebase(projectInfo);
 
  const user = useSelector((state) => state?.user?.value);

  const [isReplyField, setIsReplyField] = useState(false);
  const [childReplyField, setChildReplyField] = useState(false);
  const [replies, setReplies] = useState([]);
  const [liked, setLiked] = useState(false);
  const [commentedUser,setIsCommentedUser] = useState({})
  const [commentedTime,setcommentedTime] = useState("")
  useEffect(() => {
    const firebaseDB = initializeFirebase(projectInfo);
    const usersDbRef = ref(firebaseDB, `users/${data?.user_id}`);
    commentsReplyListing();
    userHasLiked();
    onValue(usersDbRef, (snapshot) => {
      const data = snapshot.val()
      setIsCommentedUser(data)
      // const username = (snapshot.val() && snapshot.val().username) || 'Anonymous';
      // ...
    }, {
      onlyOnce: true
    });
    setcommentedTime(timeDifferenceWithCurrenttime(data?.created_at))
    let timedifferenceCalculation = setInterval(() => {
      setcommentedTime(timeDifferenceWithCurrenttime(data?.created_at))
    }, 20*1000);
    return () => clearInterval(timedifferenceCalculation)
  }, [data]);


  const childReplyFieldHandler = (childFlag) => {
    setChildReplyField(childFlag);
  };

  const mainReplyFieldHandler = () => {
    if(!childReplyField){
      setIsReplyField(true);
    }
  };

  const userHasLiked = async () => {
    if (data?.liked_users && data?.liked_users[user]) {
      setLiked(true);
    } else {
      setLiked(false);
    }
  };
  const commentsReplyListing = () => {
    const replyArray = [];
    for (let key in data?.replies) {
      // Check if the property is own property of the object, not inherited from prototype chain
      if (data?.replies.hasOwnProperty(key)) {
        const value = data?.replies[key];
        replyArray.push({ ...value, replyId: key });
      }
    }
    setReplies(replyArray?.filter((item) => item?.delete_status !== true));
  };

  const likeHandler = () => {
    // const
    const dbRef = ref(firebaseDB, `comments/${data?.videoId}/${data?.id}`);
    const dbRefLikedUsers = ref(firebaseDB, `comments/${data?.videoId}/${data?.id}/liked_users/${user}`);
    update(dbRef, {
      likes: increment(liked ? -1 : 1),
    });
    set(dbRefLikedUsers, liked ? 0 : 1);
  };
  const reportHandler = () => {
    if (userHasReported() === false) {
      const dbRef = ref(firebaseDB, `comments/${data?.videoId}/${data?.id}`);
      const dbRefLikedUsers = ref(firebaseDB, `comments/${data?.videoId}/${data?.id}/reported_users/${user}`);
      update(dbRef, {
        reported_count: increment(1),
      });
      set(dbRefLikedUsers, 1);
      toast.success("Successfully reported", {
        position: "top-center",
      });
    } else {
      toast.error("Already reported", {
        position: "top-center",
      });
    }
  };

  const userHasReported = () => {
    if (data?.reported_users && data?.reported_users[user]) {
      return true;
    } else {
      return false;
    }
  };

  const deleteCommentHandler = () => {
    const dbRef = ref(firebaseDB, `comments/${data?.videoId}/${data?.id}`);
    update(dbRef, {
      delete_status: true,
      deleted_at: getCurrentUTCTimestamp(),
    });
  };
  return (
    <div className="eachComment" >
      <div className="containers">
        <div className="commentItem">
          <div className="left">
            {
              commentedUser?.image ? (
                <div className="profileImageContainer">
                  <img src={commentedUser?.image} alt="Profile" />
                </div>
              ):(
                <span className="nameAvatar">{commentedUser?.name?.slice(0, 1)?.toUpperCase()}</span>

              )
            }
          </div>
          <div className="right">
            <h1 className="name">{commentedUser?.name}</h1>
            <span className="time">{commentedTime}</span>
            <p className="comment">{data?.comment_text}</p>
            <div className="actions">
              <span className="item" onClick={likeHandler}>
                <span className="icon">
                  <LikeIcon style={liked ? { fill: "red" } : {}} />
                </span>
                {data?.likes > 0 && data?.likes} {data?.likes >= 2 ? "Likes" : "Like"}
              </span>
              <span className="item" onClick={() => mainReplyFieldHandler()}>
                <span className="icon">
                  <ReplyIcon />
                </span>
                {data?.reply_count && data?.reply_count > 1 && data?.reply_count}{" "}
                {data?.reply_count && data?.reply_count > 1 ? "Replies" : "Reply"}
              </span>
              {data?.user_id === Number(user) ? (
                <span className="item" onClick={() => deleteCommentHandler()}>
                  <span className="icon">
                    <DeleteIcon />
                  </span>
                  <Translate textKey={'delete'} />
                </span>
              ) : (
                <span className="item" onClick={() => reportHandler()}>
                  <span className="icon">
                    <FlagIcon />
                  </span>
                  <Translate textKey={'report'} />
                </span>
              )}
            </div>
            {replies?.map((item) => (
              <CommentReplies
                data={data}
                replies={item}
                mainReplyField={isReplyField}
                childReplyFieldHandler={childReplyFieldHandler}
                setIsReplyField={setIsReplyField}
                projectInfo={projectInfo}
              />
            ))}
          </div>
        </div>
        {isReplyField && <ReplyField className={childReplyField?"child":"parent"} setIsReplyField={setIsReplyField} data={data} childReplyFieldHandler={childReplyFieldHandler} projectInfo={projectInfo} />}
      </div>
    </div>
  );
};

export default CommentItem;
