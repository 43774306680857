import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import * as service from "./service";
import { toast } from "react-toastify";
import Translate from "../../../Components/Multilanguage/Translate";

const SetPassword = ({accountDetails}) => {
    const projectInfo = useSelector((state) => state?.projectInfo?.value);
    const accessToken = useSelector((state) => state?.accessToken?.value);
    const location = useLocation();
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});


    const navigate = useNavigate();

    const appInfo = {
        projectDetails: projectInfo,
        accessToken: accessToken,
    };

    useEffect(() => {

        window.scroll(0, 0);
    }, []);

    const submitHandler = (e) => {
        e.preventDefault();
        if (validation() === true) {
            updatePassword();
        } else {
            toast.error("Check your fields!", {
                position: "bottom-center",
            });
        }
    };

    const validation = () => {
        let validationStatus = true;
        let errors = {};
        const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
        const { password, email, confirmPassword } = values;
    
        if (!email && !accountDetails?.email_exists) {
          errors.email = <Translate textKey={'email_required_message'} />;
          validationStatus = false
        } else if (!email?.match(emailRegex) && !accountDetails?.email_exists) {
          errors.email = <Translate textKey={'invalid_email_id'} />;
          validationStatus = false
        }
    
        if (!password) {
          errors.password = <Translate textKey={'password_required'} />;
          validationStatus = false
        } else if (!password?.match(passwordRegex)) {
          errors.password = <Translate textKey={'password_validation_message'} />;
          validationStatus = false
        }
    
        if (!confirmPassword) {
          errors.confirmPassword = <Translate textKey={'confirm_password_required'} />;
          validationStatus = false
        } else if (confirmPassword !== password) {
          errors.confirmPassword = <Translate textKey={'password_matching_error_message'} />;
          validationStatus = false
        }
        setErrors(errors)
        return validationStatus;
    
      };
    const updatePassword = async () => {

        try {
            const response = await service.updatePassword(appInfo, values);

            if (response?.data?.success === true) {
                toast.success(response?.data?.message, {
                    position: "bottom-center",
                });
                setTimeout(() => {
                    navigate("/");
                }, 3000);
            } else {
                toast.error(response?.data?.message, {
                    position: "bottom-center",
                });
            }
        } catch (err) {
            toast.error(err?.response?.data?.message, {
                position: "bottom-center",
            });
        }


    };

    const handleUpdate = (item) => {
        const { name, value } = item?.target;
        handleChange(name, value);
    };

    const handleChange = (name, value) => {
        setValues({
            ...values,
            [name]: value,
        });
    };

    return (
        <div className="row">
            <h1 className="heading"><Translate textKey={'set_password_caps'} /> </h1>
            <div className="inputField">
                <label htmlFor="currentPassword"><Translate textKey={'email'} /></label>
                <input
                    type="email"
                    name="email"
                    id="email"
                    value={accountDetails?.email_exists ? accountDetails?.user_email : values?.email}
                    disabled={accountDetails?.email_exists}
                    onChange={(item) => handleUpdate(item)}
                />
                {errors?.email && (
                    <span className="error">{errors?.email}</span>
                )}            </div>

            <div className="inputField">
                <label htmlFor="newPassword"> <Translate textKey={'new_password'} /></label>
                <input
                    type="password"
                    name="password"
                    id="newpassword"
                    value={values?.password}
                    onChange={(item) => handleUpdate(item)}
                />
                {errors?.password && (
                    <span className="error">{errors?.password}</span>
                )}            </div>

            <div className="inputField">
                <label htmlFor="confirmPassword"><Translate textKey={'confirm_password'} /></label>
                <input
                    type="password"
                    name="confirmPassword"
                    id="confirmpassword"
                    value={values?.confirmPassword || ""}
                    onChange={(item) => handleUpdate(item)}
                />
                {errors?.confirmPassword && (
                    <span className="error">{errors?.confirmPassword}</span>
                )}            </div>
            <div
                className="buttonContainer"
                onClick={(e) => submitHandler(e)}
            >
                <div className="background"></div>
                <button type="submit" className="bg-button">
                <Translate textKey={'save_changes'} />
                </button>
            </div>
        </div>
    );
};

export default SetPassword;
