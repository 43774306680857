import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../Loading/Loading";
import ShowCard from "../ShowCard/ShowCard";
import * as service from "./service";
import { useWindowWidth } from "@react-hook/window-size";
import { rowItemCount } from "../../utils/utils";
import useScrollPosition from "../ScrollPosition/useScrollPosition";
import Translate from "../Multilanguage/Translate";
import MagazineCard from "../MagazineCard/MagazineCard";

const CommonPage = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const thumbnailOrientation = projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION;

  const [shows, setShows] = useState([]);
  const [rowItemsClass,setRowItemsClass] = useState("")
  const [sliceCount,setSliceCount] = useState(20)
  const windowSize = useWindowWidth()

  const [img, setImg] = useState(true);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const {careers} = useParams();
  const type = location?.state?.type;
  const navigate = useNavigate();
  const containerRef = useRef(null)
  const scrollPosition = useScrollPosition();
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };

  useEffect(()=>{
    if(!loading && sliceCount<shows?.shows?.length){
      lazyLoad()
    }
  },[loading,scrollPosition,shows])
  useEffect(() => {
    // if (menuItem) {
    fetchCategoryDetails();
    // } else {
    //   fetchCategoryDetails();
    // }
  }, [location]);

  useEffect(() => {
    if(windowSize>980){
      if(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT){
        setRowItemsClass(rowItemCount(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT))
      }else{
        setRowItemsClass("rowCount6")
      }
    }else{
      setRowItemsClass("rowCount6")
    }
  }, [projectInfo,windowSize]);

  const  fetchCategoryDetails= async () => {
    setLoading(true)
    try {
      const itemResponse = await service.getAllShowsOfCategory(appInfo, careers);
      if (itemResponse?.status === 200) {
        setShows(itemResponse?.data?.data);
        setImg(false);
        setLoading(false)
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "top-center",
      });
      setLoading(false)

    }
  };
  // const fetchCategoryDetails = async () => {
  //   setLoading(true)
  //   try {
  //     const categoryResponse = await service.getAllShowsOfCategory(
  //       appInfo,
  //       location?.state
  //     );
  //     if (categoryResponse?.status === 200) {
  //       setShows(categoryResponse?.data?.data);
  //       setImg(true);
  //       setLoading(false)
  //     }
  //   } catch (err) {
  //     setLoading(false)
  //     toast.error(err?.response?.data?.message, {
  //       position: "top-center",
  //     });
  //   }
  // };

  const lazyLoad = () => {
    const containerHeight = containerRef?.current?.clientHeight;
    if(scrollPosition + 2200 >= containerHeight){
      setSliceCount(sliceCount+20)
    }
  }

  if(loading){
    return(
      <Loading/>
    )
  }


  return (
    <>
   
      <div className="commonPage" ref={containerRef}
         style={{
          backgroundColor: projectInfo?.projectConfig?.config?.BACKGROUND_COLOR  || "--secondary-bg-color",
        }}
      >    
      {shows?.shows?.length  ===0 | Object.entries(shows).length === 0  ? 
  
        <h1
               style={{
                 color: "#E72A31",
                 display:"flex",
                 justifyContent:'center',
             
               }}
             >
               <Translate textKey={'no_data_found'} />
             </h1>

    
 
     :
      <div className="wrapper">
        
        {
       shows?.banner ? <div className="bgImageContainer">
       <div className="top inside">
       <h1 className="heading"
           style={{
            color: projectInfo?.projectConfig?.config?.BACKGROUND_COLOR ? "var(--grey-color)" : "var(--heading-color)" 
          }}>
        {shows?.category_name}
      </h1>
          <p className="desc">{shows?.synopsis}</p>
        </div>
          <img
            src={shows?.banner}
            alt="BgImage"
          />
           <div className="bannerArrow">
            
           </div>      
          <div className="bottomGradient" />   
          
        </div>
        :
        <div className="top">
        <h1 className="heading"
           style={{ color: projectInfo?.projectConfig?.config?.BACKGROUND_COLOR
              ? "var(--grey-color)"  
              : "var(--heading-color)" 
             }}>{shows?.category_name}</h1>
        <p className="desc">{shows?.synopsis}</p>
      </div>
        
      }
        
        
        <div className="bottom">
          <div className="itemsContainer">
            {shows?.category_name!=="Magazines"?
                  (shows?.shows?.slice(0,sliceCount)?.map((item, index) => (
                    <div
                    className={
                      thumbnailOrientation === `PORTRAIT` 
                        ? `items portrait ${rowItemsClass}`
                        : `items landscape ${rowItemsClass}`
                    }
                    >
                      <ShowCard
                        data={item}
                        key={index}
                        imageUrl={img}
                        season={false}
                        metaData={true}
                        cardHover={true}
                        modalPageNavigation={false}
                        type={type}
                      />
                    </div>
                   ))):(
                  shows?.shows?.slice(0,sliceCount)?.map((item, index) => (    
                    <div
                    className={
                    shows?.category_name==="Magazine"
                        ? `items portrait ${rowItemsClass}`
                        : `items landscape ${rowItemsClass}`
                    }
                    >
                      <MagazineCard
                      data={item}
                      metaData={true}
                      cardHover={true}
                      />
                    </div>
                  ))
                  )
                }
          </div>
        </div>
      </div>
         
         }
        </div>  
    </>

  );
};

export default CommonPage;
