import React, { useEffect, useState } from "react";
import initializeFirebase from '../../../firebaseConfig';
import { ref, onValue, set, push, off } from "firebase/database";
import { useSelector } from "react-redux";
import { getCurrentUTCTimestamp, timeDifferenceWithCurrenttime } from "../../../utils/utils";
import CommentReply from "./ReplyField";
import CommentItem from "./CommentItem";
import { toast } from "react-toastify";
import Translate from "../../../Components/Multilanguage/Translate";


let sortType = ""

const Comment = ({ videoId , projectInfo  }) => {

  const firebaseDB = initializeFirebase(projectInfo);
  const userDetails = useSelector((state) => state?.userDetails?.value);
  const user = useSelector((state) => state?.user?.value);
  const [comments, setComments] = useState([]);
  const [commentInputValue, setCommentInputValue] = useState();
  const [selectedSort, setSelectedSort] = useState("default");
  
  useEffect(() => {
    const firebaseDB = initializeFirebase(projectInfo);
    const dbRef = ref(firebaseDB, `comments/${videoId}`);
    onValue(dbRef, (snapshot) => {
      const data = snapshot.val();
      const commentArray = [];
      if (snapshot.exists()) {
        snapshot.forEach((childSnapshot) => {
          let keyName = childSnapshot.key;
          let data = childSnapshot.val();
         
          commentArray.push({ ...data, id: keyName, videoId: videoId });
        });
        if (sortType === "oldest") {
          setComments(commentArray?.filter(item => item?.delete_status !== true));

        } else {
          setComments(commentArray?.reverse()?.filter(item => item?.delete_status !== true));

        }
      } else {
        setComments(null)
      }
    });

    return () => off(dbRef)
  }, [videoId , projectInfo]);

  const postCommentHandler = () => {
    if (commentInputValue) {
      push(ref(firebaseDB, `comments/${videoId}`), {
        comment_text: commentInputValue,
        user_id: Number(user),
        created_at: getCurrentUTCTimestamp(),
      }).then(() => {
        setCommentInputValue("")
      });
    } else {
      toast.error("Comment Field is required!", {
        position: "top-center"
      })
    }
  };

  const commentsSortHandler = (type) => {
    if (type === "default" || type === "newest") {
      if (selectedSort === "oldest") {
        setComments(comments?.reverse()?.filter(item => item?.delete_status !== true))
      } else {
        setComments(comments?.filter(item => item?.delete_status !== true))
      }
    } else if (type === "oldest") {
      setComments(comments?.reverse()?.filter(item => item?.delete_status !== true))

    }
  }

  const sortHandleChange = (item) => {
    const { value } = item?.target;
    setSelectedSort(value)
    commentsSortHandler(value)
    sortType = value
  }

  return (
    <div className="commentsContainer">
      <div className="top">
        <div className="head">
          <span className="commentsCount">{comments ? comments?.length : "0"} Comments</span>
          <div className="sortContainer">
            <select name="sort" id="sort" onChange={(e) => sortHandleChange(e)}>
              <option value="default" ><Translate textKey={'sort_by'} />..</option>
              <option value="newest" ><Translate textKey={'sort_by_newest'} /></option>
              <option value="oldest"><Translate textKey={'sort_by_oldest'} /></option>
            </select>
          </div>
        </div>
        <div className="textContainer">
          <div className="imageContainer">
            {
              userDetails?.user_image ? (
                <img src={userDetails?.user_image} alt="Profile" />
              ) : (
                <span className="nameAvatar">{userDetails?.slice(0, 1)?.toUpperCase()}</span>

              )
            }
          </div>
          <div className="inputContainer">
            <textarea
              name="comment"
              id="comment"
              value={commentInputValue}
              onChange={(e) => setCommentInputValue(e.target.value)}
              cols="30"
              rows="10"
              placeholder="Add your comment"
            ></textarea>
            <div className="actions">
              {/* <p>
                Having trouble? <a href="https://redeemtv.com/faq" target="_blank"><span>Please read the FAQ</span></a>
              </p> */}
              <div className="buttonContainer">
                <button className="submit" onClick={postCommentHandler}>
                <Translate textKey={'post_comment'} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bottom">
        <div className="comments">
          {comments?.map((item, index) => (
            <CommentItem data={item} projectInfo={projectInfo} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Comment;
