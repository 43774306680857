import { createSlice } from '@reduxjs/toolkit';

export const trailerModalSlice = createSlice({
  name: 'trailerModal',
  initialState: {
    value: {
      isOpen: false,
      teaser: null,
      teaserVanityUrl: null,
      showDetails: null,
    },
  },
  reducers: {
    setTrailerModal: (state, action) => {
      state.value = action.payload;
    },
    closeTrailerModal: (state) => {
      state.value = {
        isOpen: false,
        teaser: null,
        teaserVanityUrl: null,
        showDetails: null,
      };
    },
  },
});

export const { setTrailerModal, closeTrailerModal } = trailerModalSlice.actions;

export default trailerModalSlice.reducer;