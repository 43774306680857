// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./index.css";
// import App from "./App";
// // import reportWebVitals from './reportWebVitals';
// import store from "./Redux/store";
// import { Provider } from "react-redux";
// import { BrowserRouter } from "react-router-dom";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <Provider store={store}>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </Provider>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// // reportWebVitals();

import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider, useTranslation } from 'react-i18next';
import i18n from 'i18next';
import "./index.css";
import App from "./App";
// import reportWebVitals from './reportWebVitals';
import store from "./Redux/store";
import { Provider, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';
const root = ReactDOM.createRoot(document.getElementById("root"));

const Root = () => {
  const isRTL = useSelector((state) => state?.rtl?.isRTL);

  useEffect(() => {
    const rootElement = document.getElementById('root');
    if (isRTL) {
      rootElement.classList.add('rtl');
    } else {
      rootElement.classList.remove('rtl');
    }
  }, [isRTL]);

  return <App />;
};

root.render(
  <Provider store={store}>
  <I18nextProvider i18n={i18n}>
    <BrowserRouter>
      {/* <App /> */}
      <Root />
    </BrowserRouter>
  </I18nextProvider>
</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
