import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Translate from "../../../Components/Multilanguage/Translate";

const PlanDetails = ({ projectInfo, accountDetails, setCancelSubModal }) => {
    useEffect(() => {
        window.scroll(0, 0);
      }, []);
    return (
    <div className="planDetails">
      <h1 className="heading">
      <Translate textKey={'app_subscription'}/>{" "}
      </h1>
      {accountDetails?.cancel_status === false ? (
        <>
          <h2 className="planName">{accountDetails?.subscription_name}</h2>
          <div
            className="buttonContainer"
            
          >
            <button onClick={() => setCancelSubModal(true)}><Translate textKey={'cancel_membership'}/></button>
          </div>
        </>
      ) : (
        <p><Translate textKey={'subscription_cancelled'}/></p>
      )}
      <Link to="billing-activity">
      <span className="link"><Translate textKey={'billing_details_caps'} /></span>
      </Link>
      {accountDetails?.cancel_status === true ? (
          <Link to={projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_REQUIRED == 'true' ? "/checkout" : "/subscription"}>
             <span className="link"><Translate textKey={'subscribe'} /></span>
          </Link>
             ) : accountDetails?.change_plan_required === true && (accountDetails?.subscription_type === 3 || accountDetails?.subscription_type === 4) &&
             <>
                {projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_REQUIRED == 'true' ? <Link
                   to="/checkout"
                   state={{ type: "upgrade", subId: accountDetails?.sub_id }}
                    >
                   <span className="link"><Translate textKey={'change_plan_caps'} /></span>
                   </Link> :
                   <Link to={projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_REQUIRED == 'true' ? "/checkout" : "/subscription"}
                    state={{ type: "upgrade", subId: accountDetails?.sub_id }}
                    >
                    <span className="link"><Translate textKey={'change_plan_caps'} /></span>
                    </Link>}
              </>
          }
    </div>
  );
};

export default PlanDetails;
