import React, { useEffect, useState } from "react";
import { ReactComponent as MailIcon } from "../../../assets/Icons/mailIcon.svg";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Loading from "../../Loading/Loading";
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import { confirmLogin } from "../../../Screens/LoginRequest/service";
import { toast } from "react-toastify";
import AppLoginModal from "./AppLoginModal";
import Translate from "../../Multilanguage/Translate";


const LoginRequestModal = ({ data, loading, appInfo }) => {
  const [open, setOpen] = React.useState(true);
  const [app, setApp] = useState("")
  const [appLoginModal, setAppLoginModal] = useState(false)

  useEffect(() => {
    if (data?.device?.toLowerCase()?.includes("web")) {
      setApp("web")
    } else if (data?.device?.toLowerCase()?.includes("android")) {
      setApp("android")
    } else if (data?.device?.toLowerCase()?.includes("ios")) {
      setApp("ios")
    }
  }, [data])

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick")
      return;
    setOpen(false);
  };

  const loginHandler = () => {
    if (data?.id) {
      loginConfirmationRequest()
    }
  }

  const loginConfirmationRequest = async () => {
    try {
      const response = await confirmLogin(appInfo, data?.id)
      if (response?.status === 200) {
        if (app === "web") {
          toast.success(response?.data?.message)
          setTimeout(() => {
            window.location.href = response?.data?.data?.url
            // console.log("redirect to:",response?.data?.data?.url);
          }, 1000);
        } else if (app === "android" || app === "ios") {
          setAppLoginModal(true)
        }
      }

    } catch (err) {
    }
  }
  return (
    appLoginModal ? (
      <AppLoginModal app={app} />
    ) : (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        transitionDuration={500}
        className="loginRequestModal"
        maxWidth="xl"
      >
        <DialogContent>

          <h4><Translate  textKey={'login_from_device'} /></h4>
          <div className="requestDetails">
            <div className="oneRow">
              <span className="label"><Translate textKey={'location'} /> </span>
              {
                loading ? (
                  <Box sx={{ width: 100 }}>
                    <Skeleton animation="wave" />
                  </Box>
                ) : (

                  <span className="value">{data?.location}</span>
                )
              }
            </div>
            <div className="oneRow">
              <span className="label"><Translate textKey={'device_type'} /></span>
              {
                loading ? (
                  <Box sx={{ width: 100 }}>
                    <Skeleton animation="wave" />
                  </Box>
                ) : (
                  <span className="value">{data?.device}</span>

                )
              }
            </div>
          </div>
          <p><Translate textKey={'complete_login_request_click'} /></p>
          <div className="buttonContainer" onClick={loginHandler}>
            <div className="background"></div>
            <button><Translate textKey={'continue'} /></button>
          </div>
        </DialogContent>
      </Dialog>
    )
  );
};

export default LoginRequestModal;
