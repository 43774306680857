import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PlayIcon } from "../../assets/Icons/play.svg";
import { useWindowWidth } from "@react-hook/window-size";
import { useSelector } from "react-redux";
import Translate from "../../Components/Multilanguage/Translate";

const PlayerShowCard = ({ data }) => {
  const navigate = useNavigate()
  const windowSize = useWindowWidth();
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const showClickHandler = () => {
    if(data?.video_id != null)
      {
        navigate(`/videos/${data?.video_vanity_url}`, {state: { videoDetails:data }, })
      }
      else{
        navigate(`/movies/${data?.vanity_url}`, {
          state: { showId: data?.show_id, type: data?.type },
        });
      }
      
  };
  return (
    <div className="playerShowCard">
      <div className="imageContainer">
        <img src={windowSize<640?data?.logo?data.logo:data?.banner:data?.banner || data?.logo_thumb} alt="Banner" />
        <div className="playIconContainer" onClick={showClickHandler}>
          <PlayIcon />
        </div>
        <div className="metaData">
            <span className="categoryName">{data?.categories?.length>0&&data?.categories[0]?.category_name}</span>
            <h1 className="showName">{data?.show_name}</h1>
          {data?.director && <span className="director"><Translate textKey={'directed_by'} />  {data?.director}</span>}
          <span className="year">{data?.year}</span>
          <p className="description">{data?.synopsis?.slice(0,250)}{data?.synopsis?.length>250&&"..."}</p>
        </div>
        <div className="bottomGradient"></div>
      </div>
    </div>
  );
};

export default PlayerShowCard;
