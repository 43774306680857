import React, { useState, useEffect } from "react";
import { ReactComponent as PlayIcon } from "../../../assets/Icons/play.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";
import 'swiper/css/pagination';
import { ReactComponent as PremiumIcon } from "../../../assets/Icons/premium.svg";
import { ReactComponent as AddListIcon } from "../../../assets/Icons/addToWatchlist.svg";
import { ReactComponent as RemoveListIcon } from "../../../assets/Icons/removeFromWatchlist.svg";
import { updateWatchlistData } from "../../../network/service";
import { getMyListData } from "../../../Redux/MyList/MyListSlice";
// import { appInfo} from "../service";
import { EffectFade, Thumbs, Autoplay,Pagination } from "swiper";
import PlayerShowCard from "../../../Components/PlayerShowCard/PlayerShowCard";
import { fetchWatchlistShows } from "../../../Screens/MyList/service";
import NotificationModal from "../../../Components/Modals/NotificationModal";

const Featured = ({ data }) => {

  const dispatch = useDispatch();
  const [watchlistStatus, setWatchlistStatus] = useState({});
  const [showNotification, setShowNotification] = useState(false);
  const [currentShowId, setCurrentShowId] = useState(null);
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.value);
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);

  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };

  useEffect(() => {
    if (data?.shows?.length > 0) {
      const initialStatus = {};
      data.shows.forEach((item) => {
        initialStatus[item.show_id] = item.watchlist_flag;
      });
      setWatchlistStatus(initialStatus);
    }
  }, [data]);

  const fetchWatchlist = async () => {
    const response = await fetchWatchlistShows(appInfo);
    if (response?.status === 200) {
      dispatch(
        getMyListData({
          myList: response?.data?.data,
        })
      );
    }
  };

  const updateWatchlist = async (showId, flag) => {
    try {
      const response = await updateWatchlistData(appInfo, showId, flag);

      if (response?.status === 200) {
        setWatchlistStatus((prevStatus) => ({
          ...prevStatus,
          [showId]: flag,
        }));
        fetchWatchlist();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleWatchlistToggle = (showId) => {
    setCurrentShowId(showId);
    if (user) {
      const currentFlag = watchlistStatus[showId] === 1 ? 0 : 1;
      updateWatchlist(showId, currentFlag);
    } else {
      setShowNotification(true);
    }
  };

  return (
    <div
      className="featured"
      style={{
        borderBottomColor: projectInfo?.projectConfig?.config?.BACKGROUND_COLOR || "--border-color",
      }}
    >
      <Swiper
        watchSlidesProgress
        slidesPerView={1}
        effect={"fade"}
        spaceBetween={0}
        loop={false}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        className="mySwiper"
        modules={[EffectFade, Autoplay, Pagination]}
      >
        {data?.shows?.map((item, index) => (
          <SwiperSlide key={index}>
            <PlayerShowCard data={item} />
            <div
              className="addToWatchList"
              onClick={() => handleWatchlistToggle(item.show_id)}
              title={watchlistStatus[item.show_id] === 1 ? "Remove from Watchlist" : "Add to Watchlist"}
            >
              {watchlistStatus[item.show_id] === 1 ? <RemoveListIcon /> : <AddListIcon />}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {showNotification && <NotificationModal onClose={() => setShowNotification(false)} />}
    </div>
  );
};

export default Featured;
