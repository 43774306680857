import React, { useEffect, useState, useRef } from "react";
import ShowCard from "../../../Components/ShowCard/ShowCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MagazineCard from "../../../Components/MagazineCard/MagazineCard";
import Translate from "../../../Components/Multilanguage/Translate";

const Categories = ({ data,thumbnailOrientation ,type }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const isRTL = useSelector((state) => state?.rtl?.isRTL);
  const navigate = useNavigate();
  const swiperRef = useRef(null);

  const [slidesPerView, setSlidesPerView] = useState(6);
  const [isLTR, setIsLTR] = useState(false);

  const handleSwiperInit = (swiperInstance) => {
    swiperRef.current = swiperInstance;
  };

  useEffect(() => {
    const updateSlidesPerView = () => {
      const width = window.innerWidth;
      if (width >= 1200) {
        setSlidesPerView(
          projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT
            ? projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT
            : thumbnailOrientation === "PORTRAIT"
            ? 6
            : 5
        );
      } else if (width >= 980) {
        setSlidesPerView(
          projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT
            ? projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT
            : 3
        );
      } else if (width >= 768) {
        setSlidesPerView(thumbnailOrientation === "PORTRAIT" ? 6 : 3);
      } else if (width >= 640) {
        setSlidesPerView(3);
      } else if (width >= 480) {
        setSlidesPerView(thumbnailOrientation === "PORTRAIT" ? 3 : 2);
      } else {
        setSlidesPerView(thumbnailOrientation === "PORTRAIT" ? 2 : 1);
      }
    };

    updateSlidesPerView();
    window.addEventListener("resize", updateSlidesPerView);
    return () => window.removeEventListener("resize", updateSlidesPerView);
  }, [thumbnailOrientation, projectInfo]);

  useEffect(() => {
    setIsLTR(data?.shows?.length > slidesPerView);
  }, [data?.shows?.length, slidesPerView]);

  const displayedShows = ( isRTL && isLTR ) ? [...data?.shows].reverse() : data?.shows;

  return (
    <div
      className={`categoriesContainer ${( isRTL && isLTR ) ? "ltr" : ""}`}
        style={{
          borderBottomColor: projectInfo?.projectConfig?.config?.BACKGROUND_COLOR || "--border-color",
          "--swiper-prev-background":
            projectInfo?.projectConfig?.config?.BACKGROUND_COLOR
              ?  projectInfo?.projectConfig?.config?.BACKGROUND_COLOR 
              : "linear-gradient(180deg, rgba(254, 254, 254, 1) 0%, rgba(254, 254, 254, 0.9) 30%, rgba(236, 236, 236, 0.5) 100%)"
        }}       
     >
      <div className="wrapper">
      <div
          className="categoryNameContainerMedium"
          onClick={() =>
            navigate(`/category/${data?.key}`, {
              state: {careers:data?.key,type:data?.type},
            })
          }
        >
          <h1>{data?.category_name}</h1>
          </div>
        <div className="itemsContainer">
        <div
          className="categoryNameContainer"
          onClick={() =>
            navigate(`/category/${data?.key}`, {
              state: {careers:data?.key,type:data?.type},
            })
          }
        >
          <div className="categoryDetails">
            <h1>{data?.category_name}</h1>
            <span className="seeAll"><Translate textKey={'see_all'}/> ({data?.shows_count ? data?.shows_count : data?.shows?.length})</span>
          </div>
        </div>
          <Swiper
            key={`${isRTL}-${isLTR}`}
            modules={[Navigation]}
            spaceBetween={1.5}
            slidesPerView={slidesPerView}
            navigation={true}
            watchSlidesProgress
            loop={false}
            onSwiper={handleSwiperInit} 
            initialSlide={( isRTL && isLTR ) ? displayedShows.length - 1 : 0}
            breakpoints={{
              320: {
                slidesPerView: thumbnailOrientation=="PORTRAIT"?2:1,
                spaceBetween: 2,
              },
              480:{
                slidesPerView: thumbnailOrientation=="PORTRAIT"?3:2,
                spaceBetween: 5,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: thumbnailOrientation=="PORTRAIT"?6:3,
                spaceBetween: 1.5,
              },
              980: {
                slidesPerView: projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT?projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT:3,
                spaceBetween: 1.5,
              },
              1200: {
                slidesPerView:projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT ?(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT) :thumbnailOrientation=="PORTRAIT"?6:5,
                spaceBetween: 1.5,
              },
             
            }}
          >
            {displayedShows?.map((item, index) => (
              <SwiperSlide key={index}>
                
                {data?.type!=="MAGAZINES" ?
                  (
                      <ShowCard
                      data={item}
                      season={false}
                      metaData={true}
                      type={data?.type}
                      key={data?.key}
                      
                    />
                  ):
                  (
                    <MagazineCard
                    data={item}
                    url={data.url}
                    metaData={true}
                    cardHover={true}
                    />
                  )  
                }
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Categories;
