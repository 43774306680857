import React, { useEffect, useState } from 'react'
import { fetchAppInfo } from '../../network/service';
import parse from "html-react-parser";
import { ToastContainer, toast } from "react-toastify";
import Loading from '../../Components/Loading/Loading';
import { useSelector } from "react-redux";
import Translate from "../../Components/Multilanguage/Translate";

const TermsAndConditions = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scroll(0,0)
    fetchTermsAndConditions();
  }, []);

  const fetchTermsAndConditions = async () => {
    setLoading(true);
    try{
      const params = {
        type: "TERMS_AND_CONDITIONS",
      };
      const response = await fetchAppInfo(params);
     
      if (response?.status === 200) {
        setData(response?.data?.data?.value);
        setLoading(false)
      }
    }catch(err){
      setLoading(false);
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
    
  };
  if(loading){
    return(
      <Loading/>
    )
  }
  return (
    <div className="termsAndConditions" 
        style={{
        backgroundColor: projectInfo?.projectConfig?.config?.BACKGROUND_COLOR || "var(--secondary-bg-color)" 
      }}>
      <ToastContainer/>
      
      <div className="wrapper">
      
      { data ? parse(data):<h1 className='noData'><Translate textKey={'no_data'} /></h1>}
        </div>
      {/* <div dangerouslySetInnerHTML={{__html: '<strong>strong text</strong>'}} /> */}
    </div>
  )
}

export default TermsAndConditions
