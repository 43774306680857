import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useSelector } from 'react-redux';
import Translate from "../../Multilanguage/Translate";


const AppLoginModal = ({app}) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const [open, setOpen] = useState(true);


  const handleClose = (event,reason) => {
    if (reason && reason == "backdropClick") 
    return;
    setOpen(false);
  };
  const openAppHandler = () => {
    handleClose()
    window.location.href = projectInfo?.projectConfig?.config?.APPLINK_URL
  }
  return (
    <Dialog
        open={open}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        transitionDuration={500}
        maxWidth="xl"
        className='appLoginModal'
      >
        <DialogContent>
        <p><Translate textKey={'app_login_modal_message'}/></p>
        <div className="buttonContainer" onClick={openAppHandler}>
            <div className="background"></div>
            <button><Translate textKey={'open_app'}/></button>
        </div>
        <span><Translate textKey={'your'}/> {app} <Translate textKey={'app_update_automatically'} /></span>
        </DialogContent>
      </Dialog>
  )
}

export default AppLoginModal
