import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { getDeviceSubscription } from "../../Redux/DeviceSubscriptionData/deviceSubscriptionSlice";
import { getProjectInfo } from "../../Redux/ProjectInfo/ProjectInfoSlice";
import { getUser } from "../../Redux/UserSlice/UserSlice";
import { getVideoSubscription } from "./service";
import { getAndroidSubscriptionDetails, getSubscriptionList, getUpgradeSubscriptionList } from "./service";
import Translate from "../../Components/Multilanguage/Translate";

const Subscription = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  const location = useLocation();
  const [subscriptions, setSubscriptions] = useState([]);
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
 
  
  useEffect(()=>{
    window.scroll(0,0)
  },[])
  useEffect(() => {
    
    
  }, [projectInfo]);

  useEffect(() => {
    const androidToken = urlParams.get("antkn");
    // const androidToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjaGVjayI6dHJ1ZSwiaWF0IjoxNjcwNDk4MTc2LCJleHAiOjE2NzA0OTg3NzZ9.InaDjgFOWgVkdLoqf1PSgsex4hNqqCr8JrBVtt0hqYw"
    if(location?.state?.type==="upgrade"){
      localStorage.setItem('isUpgrade', 'true');
      localStorage.setItem('previousSubId', location?.state?.subId);
      fetchUpgradeSubscription()
    }
    else if(location?.state?.eventId){
      let type = "event"
      const antkn = {
        androidToken: null,
        isAndroid: false,
      };
      dispatch(
        getDeviceSubscription({
          deviceSubscription: antkn,
        })
      );
      localStorage.setItem("deviceType", "web");

      fetchVideoSubscription(appInfo,location?.state?.eventId,type)
    }
    else if (!location?.state?.videoId) {
      if (androidToken) {
        const deviceId = urlParams.get("deviceId");
        // const deviceId=122;
        const info = {
          projectConfig: projectInfo?.projectConfig,
          geoInfo: projectInfo?.geoInfo,
          device_id: deviceId,
        };
        dispatch(
          getProjectInfo({
            projectInfo: info,
          })
        );
        fetchAndroidSubscriptionDetails(androidToken, info);
      } else {
        const antkn = {
          androidToken: null,
          isAndroid: false,
        };
        dispatch(
          getDeviceSubscription({
            deviceSubscription: antkn,
          })
        );
      localStorage.setItem("deviceType", "web");

        fetchSubscriptionList(appInfo);
      }
    } else {
      let type = "video"
      const antkn = {
        androidToken: null,
        isAndroid: false,
      };
      dispatch(
        getDeviceSubscription({
          deviceSubscription: antkn,
        })
      );
      localStorage.setItem("deviceType", "web");

      fetchVideoSubscription(appInfo,location?.state?.videoId,type)
    }
  }, []);

  const fetchUpgradeSubscription = async () => {
    try{
      const upgradeSubResponse =  await getUpgradeSubscriptionList(appInfo,location?.state?.subId)
      
      if(upgradeSubResponse?.status === 200){
        setSubscriptions(upgradeSubResponse?.data?.data);

      }
    }catch(err){

    }
  }

  const fetchVideoSubscription = async (appInfo2, videoId,type) => {
    try {
      const videoSubRespone = await getVideoSubscription(appInfo2, videoId,type);

      if (videoSubRespone?.status === 200) {
       
        setSubscriptions(videoSubRespone?.data?.data);
      }
    } catch (err) {}
  };

  const fetchAndroidSubscriptionDetails = async (androidToken, info) => {
    const appInfo2 = {
      projectDetails: info,
      accessToken: accessToken,
    };
    const videoIdAndroid = urlParams.get("vd");
   
    // const videoId = 16700
    const androidSubResponse = await getAndroidSubscriptionDetails(
      appInfo2,
      androidToken
    );
   
    if (androidSubResponse?.status === 200) {
      localStorage.setItem(
        "userId",
        androidSubResponse?.data?.data[0]?.user_id
      );
      localStorage.setItem("isAndroid", true);
      dispatch(
        getUser({
          user: androidSubResponse?.data?.data[0]?.user_id,
        })
      );
      const antkn = {
        androidToken,
        isAndroid: true,
        userId: androidSubResponse?.data?.data[0]?.user_id,
      };
      localStorage.setItem("deviceType", "android-web");
      dispatch(
        getDeviceSubscription({
          deviceSubscription: antkn,
        })
      );
      if (videoIdAndroid) {
        fetchVideoSubscription(appInfo2, videoIdAndroid,"video");
      } else {
        fetchSubscriptionList(appInfo2);
      }
    }
  };

  const fetchSubscriptionList = async (appInfo) => {
    try {
      const subListResponse = await getVideoSubscription(appInfo);
      if (subListResponse?.status === 200) {
       
        setSubscriptions(subListResponse?.data?.data);
      }
    } catch (err) {}
  };
  const handleSubscribe = (data) => {
    localStorage.setItem("selectedSubId", data?.subscription_id);
    localStorage.setItem("selectedAmount", data?.price);
    navigate("/payment", { state: { subscription: data } });
  };
 
  return (
    <>
      <div className="subscriptionList">
        <div className="wrapper">
          <h1 className="heading"><Translate textKey={'subscription_title'} /></h1>
          <div className="subscriptionDetails">
            <h2 className="heading">
            <Translate textKey={'subscription_heading'} />
            </h2>
            <ul className="conditions">
              <li>
                <span>
                <Translate textKey={'subscription_condition_one'} />
                </span>
              </li>
              <li>
                <span>
                <Translate textKey={'subscription_condition_two'} />
                </span>
              </li>
              <li>
                <span>
                <Translate textKey={'subscription_condition_three'} />
                </span>
              </li>
              <li>
                <span>
                <Translate textKey={'subscription_condition_four'} />
                </span>
              </li>
            </ul>
          </div>
          <div className="pricing">
            <ul>
              {subscriptions?.map((data, key) => (
                <li key={key}>
                  <button onClick={() => handleSubscribe(data)}>
                  <Translate textKey={'subscribe'} />
                  </button>
                  <span className="category">{data?.subscription_name}</span>
                  <span className={data?.discounted_price?"price discount":"price"}>
                    {
                    data?.trial_period
                    ?
                    `Pay ${data?.trial_amount}, then ${data?.price} ${data?.subscription_type_name} after trial`
                    :
                    data?.discounted_price
                    ?
                      (
                        <>
                      <span className="priceCut">{data?.symbol} {data?.price}</span>
                      {data?.symbol} {data?.discounted_price}
                      </>
                      )
                    :
                      `${data?.symbol} ${data?.price} ${data?.subscription_type_name}`

                    }
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscription;
