import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { ReactComponent as CloseIcon } from "../../assets/Icons/cross.svg";
import { ideaBizOTPVerify, ideaBizPurchase, ideaBizSubscribe } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';
import Translate from "../Multilanguage/Translate";

const IdeaBizPhoneModal = ({selectedSubscriptionData,ideabizToken,setIdeaBizSubscribeModal}) => {
    const projectInfo = useSelector((state) => state?.projectInfo?.value);
    const accessToken = useSelector((state) => state.accessToken?.value);
    const [phoneInputValue,setPhoneInputValue] = useState("")
    const [otpValue,setOtpValue] = useState("")
    const [serverRef,setServerRef] = useState("")
    const [error,setError] = useState("")
    const [isIdeaBizPhoneContainer,setIsIdeaBizPhoneContainer] = useState(true)
    const [isIdeaBizOTPContainer,setIsIdeaBizOTPContainer] = useState(false)
    const [isConfirmContainer,setIsConfirmContainer] = useState(false)

    const navigate = useNavigate()
    const appInfo = {
      accessToken: accessToken,
      projectDetails: projectInfo,
    };

    const closeHandler = () => {
      resetValues()
      setIdeaBizSubscribeModal(false)
    }
    
    const submitButtonHandler = () => {
      if(validation("phone")===true){
        subscribeIdeaBiz()
      }
    }

    const validation = (type) => {
      let validationStatus = true;
      let errors = ""
     if(type==="phone"){
      if(!phoneInputValue){
        errors = "Please enter a number";
        validationStatus = false
      }else if(phoneInputValue?.length>9 || phoneInputValue?.length<9){
        errors = "Please enter a valid number"
        validationStatus = false

      }
     }else if(type === "otp"){
      if(!otpValue){
        errors = "Please enter the OTP"
        validationStatus = false

      }
     }
     setError(errors)
     return validationStatus
    }

    const subscribeIdeaBiz = async () => {
        const values = {
            token:ideabizToken,
            phone:"94"+phoneInputValue,
            serviceId:selectedSubscriptionData?.ideabiz_keyword
        }
        try{
            const response =  await ideaBizSubscribe(appInfo,values)
            if(response?.status===200){
              setServerRef(response?.data?.data?.serverRef)
              setError(null)
              setIsIdeaBizPhoneContainer(false)
              setIsIdeaBizOTPContainer(true)
            }

        }catch(err){
            setError(err?.response?.data?.message)
           
        }
    }

    const otpVerifyHandler = () => {
      if(validation("otp")===true){
        otpVerify()
      }
    }

    const otpVerify =async () => {
      const values = {
        token:ideabizToken,
        pin:otpValue,
        serverRef:serverRef
    }
    try{
      const response = await ideaBizOTPVerify(appInfo,values)
      if(response?.status ===200){
        setError(null)
        setIsIdeaBizOTPContainer(false)
        setIsConfirmContainer(true)

      }
    }catch(err){
      setError(err?.response?.data?.message)
    }
    }

    const confirmSubscriptionHandler = async () => {
      const values = {
        token:ideabizToken,
        phone:"94"+phoneInputValue,
        amount:selectedSubscriptionData?.ideabiz_price,
        serviceId:selectedSubscriptionData?.ideabiz_keyword
      }
      try{
        const response = await ideaBizPurchase(appInfo,values)
        if(response?.status ===200){
          localStorage.setItem("ideabizPhone",phoneInputValue)
          localStorage.setItem("selectedAmount",selectedSubscriptionData?.ideabiz_price)
          navigate(`/success?token=${response?.data?.data?.data?.amountTransaction?.serverReferenceCode}`,{ replace: true })
        }

      }catch(err){
        setError(err?.response?.data?.message)
      }
    }

    const resetValues = () => {
      setOtpValue("")
      setPhoneInputValue("")
      setServerRef("")
    }

  return (
    <div className='ideaBizSubscribeModal'>
      <div className="overlay" onClick={closeHandler}></div>
      <div className="closeIconContainer" onClick={closeHandler}>
        <CloseIcon />
      </div>

      <div className="modalContainer">
        {
          isIdeaBizPhoneContainer&&
        <div className="phoneContainer">
          <div className="top">
              <h1 className="heading"><Translate textKey={'welcome_to'} /> {projectInfo?.projectConfig?.config?.TAB_TITLE}</h1>
              <p className="subHeading"><Translate textKey={'your'} /> {selectedSubscriptionData?.subscription_type_name} <Translate textKey={'subscription_is'} /> {selectedSubscriptionData?.ideabiz_price} + <Translate textKey={'tex'} />.</p>
              <p className="info"><Translate textKey={'number_to_subscribe'} /></p>
          </div>

          <div className="bottom">
              <label htmlFor="phone"><Translate textKey={'phone_number'} /></label>
              <select name="country" id="country">
                  <option value=""><Translate textKey={'sri_lanka'} />(+94)</option>
              </select>
              <input type="tel" id='phone' name='phone' value={phoneInputValue || ""} onChange={(e)=>setPhoneInputValue(e.target.value)} placeholder='Phone Number' />
              {
                  error&&
              <span className="error">{error}</span>
              }
              <div className="buttonContainer" onClick={submitButtonHandler}>
                  <div className="background"></div>
                  <button>
                  <Translate textKey={'submit'} />
                  </button>
              </div>
          </div>

        </div>
        }
        {
          isIdeaBizOTPContainer&&
          <div className="otpContainer">
            <h1 className="heading"><Translate textKey={'enter_code'} /></h1>
            <input type="number" name='pin' value={otpValue|| ""} placeholder='Verification Code' onChange={(e)=>setOtpValue(e.target.value)} />
           {
            error&&
            <span className="error">
              {error}
            </span>
           }
            <div className="buttonContainer" onClick={otpVerifyHandler}>
              <div className="background"></div>
              <button><Translate textKey={'verify'} /></button>
            </div>
          </div>
        }
        {
          isConfirmContainer&&
          <div className="confirmContainer">
            <h1 className="heading"><Translate textKey={'your'} /> {selectedSubscriptionData?.subscription_type_name} <Translate textKey={'subscription_is'} /> {selectedSubscriptionData?.ideabiz_price} + <Translate textKey={'tex'} />.<Translate textKey={'wants_subscribe'} /></h1>
            {
            error&&
            <span className="error">
              {error}
            </span>
           }
            <div className="buttons">
              <div className="buttonContainer" onClick={confirmSubscriptionHandler}>
                <div className="background greenBg"></div>
                <button><Translate textKey={'yes'} /></button>
              </div>
              <div className="buttonContainer" onClick={closeHandler}>
                <div className="background"></div>
                <button><Translate textKey={'no'} /></button>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default IdeaBizPhoneModal
